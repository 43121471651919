import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api/api';
import { useAuth } from '../context/AuthContext';
import { API_BASE_URL } from '../config';
import { groupDishesByCategory } from '../helpers';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Paper,
  CircularProgress,
  Snackbar,
} from '@mui/material';

// Define category translations
const categoryTranslations = {
  bg: {
    appetizers: 'Предястия',
    mainCourses: 'Основни ястия',
    desserts: 'Десерти',
    beverages: 'Напитки'
  },
  en: {
    appetizers: 'Appetizers',
    mainCourses: 'Main Courses',
    desserts: 'Desserts',
    beverages: 'Beverages'
  }
};

const MenuPreview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [menuName, setMenuName] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [bgEditorState, setBgEditorState] = useState(EditorState.createEmpty());
  const [enEditorState, setEnEditorState] = useState(EditorState.createEmpty());
  const [dishes, setDishes] = useState([]);
  const [translatedDishes, setTranslatedDishes] = useState([]);

  const toolbarOptions = {
    options: ['inline', 'textAlign'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    textAlign: {
      options: ['left', 'center', 'right'],
    },
  };

  useEffect(() => {
    if (location.state) {
      const { dishes, translatedDishes, menuName } = location.state;
      if (dishes && translatedDishes) {
        setDishes(dishes);
        setTranslatedDishes(translatedDishes);
        setMenuName(menuName || '');

        // Generate content for Bulgarian menu
        const bgContent = generateMenuContent(dishes, 'bg');
        const bgContentBlock = htmlToDraft(bgContent);
        if (bgContentBlock) {
          const bgContentState = ContentState.createFromBlockArray(bgContentBlock.contentBlocks);
          setBgEditorState(EditorState.createWithContent(bgContentState));
        }

        // Generate content for English menu
        const enContent = generateMenuContent(translatedDishes, 'en');
        const enContentBlock = htmlToDraft(enContent);
        if (enContentBlock) {
          const enContentState = ContentState.createFromBlockArray(enContentBlock.contentBlocks);
          setEnEditorState(EditorState.createWithContent(enContentState));
        }

        setIsLoading(false);
      }
    }
  }, [location.state]);

  const generateMenuContent = (menuDishes, language) => {
    const groupedDishes = groupDishesByCategory(menuDishes);
    let content = '';
    
    Object.entries(groupedDishes).forEach(([category, categoryDishes]) => {
      const translatedCategory = language === 'en' 
        ? categoryTranslations.en[category] || category
        : categoryTranslations.bg[category] || category;
      content += `<h2>${translatedCategory}</h2>`;
      categoryDishes.forEach((dish) => {
        const dishName = language === 'en' ? dish.nameEn : dish.name;
        const dishDescription = language === 'en' ? dish.descriptionEn : dish.description;
        content += `<p><strong>${dishName}</strong> - ${dish.price} BGN<br/>`;
        if (dishDescription) {
          content += `${dishDescription}`;
        }
        content += '</p>';
      });
    });
    
    return content;
  };

  const generatePDF = (language) => {
    const content = language === 'bg' ? 
      draftToHtml(convertToRaw(bgEditorState.getCurrentContent())) :
      draftToHtml(convertToRaw(enEditorState.getCurrentContent()));
    
    const element = document.createElement('div');
    element.innerHTML = content;
    document.body.appendChild(element);

    html2canvas(element).then((canvas) => {
      document.body.removeChild(element);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`menu_${language}.pdf`);
    });
  };

  const printMenu = (language) => {
    const content = language === 'bg' ? 
      draftToHtml(convertToRaw(bgEditorState.getCurrentContent())) :
      draftToHtml(convertToRaw(enEditorState.getCurrentContent()));
    
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print Menu</title></head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const saveMenu = async () => {
    try {
      const bgContent = draftToHtml(convertToRaw(bgEditorState.getCurrentContent()));
      const enContent = draftToHtml(convertToRaw(enEditorState.getCurrentContent()));
      
      await api.post(`${API_BASE_URL}/save-menu`, {
        name: menuName,
        bgMenu: bgContent,
        enMenu: enContent,
        dishes: dishes,
        translatedDishes: translatedDishes,
        userId: user.id
      });
  
      setSnackbar({ open: true, message: t('menuSavedSuccessfully') });
    } catch (error) {
      console.error('Error saving menu:', error);
      setSnackbar({ open: true, message: t('errorSavingMenu') });
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>{t('menuPreview')}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Българско меню</Typography>
            <Editor
              editorState={bgEditorState}
              onEditorStateChange={setBgEditorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbar={toolbarOptions}
              readOnly={false}
            />
          </Paper>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => generatePDF('bg')} 
            fullWidth 
            style={{ marginTop: '10px' }}
          >
            Генериране на PDF (BG)
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => printMenu('bg')} 
            fullWidth 
            style={{ marginTop: '10px' }}
          >
            Принтиране на менюто (BG)
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>English Menu</Typography>
            <Editor
              editorState={enEditorState}
              onEditorStateChange={setEnEditorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbar={toolbarOptions}
              readOnly={false}
            />
          </Paper>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => generatePDF('en')} 
            fullWidth 
            style={{ marginTop: '10px' }}
          >
            Generate PDF (EN)
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => printMenu('en')} 
            fullWidth 
            style={{ marginTop: '10px' }}
          >
            Print Menu (EN)
          </Button>
        </Grid>
      </Grid>
      <TextField
        fullWidth
        margin="normal"
        label={t('menuName')}
        value={menuName}
        onChange={(e) => setMenuName(e.target.value)}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={saveMenu} 
        fullWidth 
        style={{ marginTop: '20px' }}
      >
        {t('saveMenu')}
      </Button>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={() => navigate('/menu-history')} 
        fullWidth 
        style={{ marginTop: '20px' }}
      >
        {t('viewMenuHistory')}
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Container>
  );
};

export default MenuPreview;
