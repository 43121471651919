import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

console.log('index.js is being executed');

const root = ReactDOM.createRoot(document.getElementById('root'));

try {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  console.log('App rendered successfully');
} catch (error) {
  console.error('Error rendering App:', error);
}

window.addEventListener('error', (event) => {
  console.error('Global error caught:', event.error);
});
