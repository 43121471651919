import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api/api';
import { useAuth } from '../context/AuthContext';
import { 
  Container, Typography, List, ListItem, ListItemText, ListItemSecondaryAction,
  IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const MenuHistory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [menuHistory, setMenuHistory] = useState([]);
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [currentMenuName, setCurrentMenuName] = useState('');
  const [currentMenuIndex, setCurrentMenuIndex] = useState(null);

  useEffect(() => {
    fetchMenuHistory();
  }, []);

  const fetchMenuHistory = async () => {
    try {
      const response = await api.get('/menus');
      setMenuHistory(response.data);
    } catch (error) {
      console.error('Error fetching menu history:', error);
    }
  };

  const deleteMenu = async (menuId) => {
    try {
      await api.delete(`/menus/${menuId}`);
      fetchMenuHistory();
    } catch (error) {
      console.error('Error deleting menu:', error);
    }
  };

  const openNameDialog = (index) => {
    setCurrentMenuIndex(index);
    setCurrentMenuName(menuHistory[index].name);
    setIsNameDialogOpen(true);
  };

  const handleNameChange = async () => {
    if (currentMenuName.trim() !== '') {
      try {
        await api.put(`/menus/${menuHistory[currentMenuIndex]._id}`, { name: currentMenuName });
        fetchMenuHistory();
        setIsNameDialogOpen(false);
      } catch (error) {
        console.error('Error updating menu name:', error);
      }
    }
  };

  const navigateToMenuCreator = (menuId) => {
    navigate(`/menu-creator/${menuId}`);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>{t('menuHistory')}</Typography>
      <List>
        {menuHistory.map((menuItem, index) => (
          <ListItem key={menuItem._id}>
            <ListItemText 
              primary={menuItem.name} 
              secondary={new Date(menuItem.createdAt).toLocaleString()}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => openNameDialog(index)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => deleteMenu(menuItem._id)}>
                <DeleteIcon />
              </IconButton>
              <IconButton edge="end" aria-label="select" onClick={() => navigateToMenuCreator(menuItem._id)}>
                <ArrowForwardIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" onClick={() => navigate('/')} style={{ marginTop: '20px' }}>
        {t('backToMenu')}
      </Button>

      <Dialog open={isNameDialogOpen} onClose={() => setIsNameDialogOpen(false)}>
        <DialogTitle>{t('editMenuName')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('menuName')}
            type="text"
            fullWidth
            value={currentMenuName}
            onChange={(e) => setCurrentMenuName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNameDialogOpen(false)} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleNameChange} color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MenuHistory;
