import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from './i18n';
import { AuthProvider, useAuth } from './context/AuthContext';
import Header from './components/Header';
import MenuCreator from './components/MenuCreator';
import MenuPreview from './components/MenuPreview';
import MenuHistory from './components/MenuHistory';
import Login from './components/Login';
import Register from './components/Register';
import AdminPage from './components/AdminPage';
import Settings from './components/Settings';
import ErrorBoundary from './components/ErrorBoundary';

const theme = createTheme();

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <CircularProgress />;
  }
  
  if (!user) return <Navigate to="/login" replace />;
  if (adminOnly && !user.isAdmin) return <Navigate to="/" replace />;
  return children;
};

const AppContent = () => {
  const { loading, user } = useAuth();
  const [dishes, setDishes] = useState([]);

  console.log('AppContent rendering, loading:', loading, 'user:', user);

  if (loading) {
    console.log('AppContent is in loading state');
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  console.log('AppContent is rendering routes');

  return (
    <>
      <Header />
      <Routes>
        {console.log('Rendering Routes component')}
        <Route path="/login" element={
          <>
            {console.log('Rendering Login route')}
            <Login />
          </>
        } />
        <Route path="/register" element={
          <>
            {console.log('Rendering Register route')}
            <Register />
          </>
        } />
        <Route path="/" element={
          <PrivateRoute>
            {console.log('Rendering MenuCreator route')}
            <MenuCreator dishes={dishes} setDishes={setDishes} />
          </PrivateRoute>
        } />
        <Route path="/preview" element={
          <PrivateRoute>
            {console.log('Rendering MenuPreview route')}
            <MenuPreview dishes={dishes} />
          </PrivateRoute>
        } />
        <Route path="/menu-history" element={
          <PrivateRoute>
            {console.log('Rendering MenuHistory route')}
            <MenuHistory />
          </PrivateRoute>
        } />
        <Route path="/admin" element={
          <PrivateRoute adminOnly={true}>
            {console.log('Rendering AdminPage route')}
            <AdminPage />
          </PrivateRoute>
        } />
        <Route path="/settings" element={
          <PrivateRoute>
            {console.log('Rendering Settings route')}
            <Settings />
          </PrivateRoute>
        } />
        <Route path="*" element={
          <>
            {console.log('Rendering catch-all route')}
            <Navigate to="/" replace />
          </>
        } />
      </Routes>
    </>
  );
};

const App = () => {
  console.log('App component rendering');
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppContent />
            </ThemeProvider>
          </I18nextProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
