import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      selectLanguage: 'Select Language',
      addDish: 'Add Dish',
      category: 'Category',
      dishName: 'Dish Name',
      price: 'Price',
      description: 'Description',
      allergens: 'Allergens',
      menuPreview: 'Menu Preview',
      finishMenu: 'Finish Menu',
      goBack: 'Go Back',
      printMenuBg: 'Print Bulgarian Menu',
      printMenuEn: 'Print English Menu',
      deleteDish: 'Delete Dish',
      appetizers: 'Appetizers',
      soups: 'Soups',
      salads: 'Salads',
      mainCourses: 'Main Courses',
      desserts: 'Desserts',
      beverages: 'Beverages',
      appName: 'Lunch Menu Creator',
      menuHistory: 'Menu History',
      login: 'Login',
      logout: 'Logout',
      menuHistoryPlaceholder: 'Your menu history will be displayed here.',
      createMenu: 'Create Menu',
      selectTemplate: 'Select Template',
      defaultTemplate: 'Default Template',
      elegantTemplate: 'Elegant Template',
      modernTemplate: 'Modern Template',
      editDish: 'Edit Dish',
      save: 'Save',
      cancel: 'Cancel',
      generatePdfBg: "Generate Bulgarian PDF",
      generatePdfEn: "Generate English PDF",
      editMenu: "Edit Menu",
      backToMenu: "Back to Menu",
      editMenuName: "Edit Menu Name",
      previewMenu:"Preview Menu",
      register: 'Register',
      login: 'Login',
      logout: 'Logout',
      email: 'Email',
      password: 'Password',
      registrationSuccessful: 'Registration successful. Waiting for approval.',
      registrationFailed: 'Registration failed',
      loginFailed: 'Login failed',
      menuName: "Menu Name",
      menuSavedSuccessfully: "Menu saved successfully!",
      errorSavingMenu: "Error saving menu. Please try again.",
      saveEnPDF: "Save English PDF",
      adminDashboard: "Admin",
      formattingBg: 'Bulgarian Menu Formatting',
      formattingEn: 'English Menu Formatting',
      settings: "Settings"
    },
  },
  bg: {
    translation: {
      selectLanguage: 'Изберете език',
      addDish: 'Добавете ястие',
      category: 'Категория',
      dishName: 'Име на ястието',
      price: 'Цена',
      description: 'Описание',
      allergens: 'Алергени',
      menuPreview: 'Преглед на менюто',
      finishMenu: 'Завършете менюто',
      goBack: 'Върнете се назад',
      printMenuBg: 'Отпечатайте българското меню',
      printMenuEn: 'Отпечатайте английското меню',
      deleteDish: 'Изтрий ястието',
      appetizers: "Предястия",
      mainCourses: "Основни ястия",
      desserts: "Десерти",
      beverages: "Напитки",
      appName: 'Създател на Меню за Обяд',
      menuHistory: 'История на Менюто',
      login: 'Вход',
      logout: 'Изход',
      menuHistoryPlaceholder: 'Вашата история на менюто ще бъде показана тук.',
      createMenu: 'Създай Меню',
      selectTemplate: 'Изберете шаблон',
      defaultTemplate: 'Стандартен шаблон',
      elegantTemplate: 'Елегантен шаблон',
      modernTemplate: 'Модерен шаблон',
      editDish: 'Редактирай ястие',
      generatePdfBg: "Генериране на PDF на български",
      generatePdfEn: "Генериране на PDF на английски",
      editMenu: "Редактиране на менюто",
      backToMenu: "Обратно към менюто",
      editMenuName: "Редактиране име на менюто",
      saveMenu: "Запазване на менюто",
      cancel: "Отказ",
      save: "Запазване",
      previewMenu:"Преглед",
      register: 'Регистрация',
      login: 'Вход',
      logout: 'Изход',
      email: 'Имейл',
      password: 'Парола',
      registrationSuccessful: 'Регистрацията е успешна. Изчакване на одобрение.',
      registrationFailed: 'Регистрацията не бе успешна',
      loginFailed: 'Входът не бе успешен',
      menuName: "Име на менюто",
      menuSavedSuccessfully: "Менюто е запазено успешно!",
      errorSavingMenu: "Грешка при запазване на менюто. Моля, опитайте отново.",
      adminDashboard: "Admin",
      formattingBg: 'Българско форматиране на менюто',
      formattingEn: 'Форматиране на менюто на английски',
      settings: "Настройки"
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'bg', // Set Bulgarian as the default language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
