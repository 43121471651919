export const groupDishesByCategory = (dishes) => {
    if (!dishes || !Array.isArray(dishes)) {
      return {};
    }
    return dishes.reduce((acc, dish) => {
      if (!acc[dish.category]) {
        acc[dish.category] = [];
      }
      acc[dish.category].push(dish);
      return acc;
    }, {});
  };