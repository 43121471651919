import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, CircularProgress, LinearProgress } from '@mui/material';
import api from '../api/api';
import { useAuth } from '../context/AuthContext';

const Settings = () => {
  const [claudeUsage, setClaudeUsage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClaudeUsage = async () => {
      try {
        // Update this line to use the new endpoint
        const response = await api.get('/users/claude-usage');
        setClaudeUsage(response.data);
      } catch (error) {
        console.error('Error fetching Claude usage:', error);
        setClaudeUsage(null);
      } finally {
        setLoading(false);
      }
    };

    fetchClaudeUsage();
  }, []);

  if (loading) {
    return (
      <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <CircularProgress />
      </Container>
    );
  }

  const monthlyUsagePercentage = claudeUsage?.monthlyTokenLimit
    ? (claudeUsage.monthlyUsage.tokensUsed / claudeUsage.monthlyTokenLimit) * 100
    : 0;

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>Settings</Typography>
      <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
        <Typography variant="h6" gutterBottom>Claude Usage Statistics</Typography>
        {claudeUsage ? (
          <>
            <Typography>Total queries made: {claudeUsage.totalQueryCount}</Typography>
            <Typography>Total tokens used: {claudeUsage.totalTokensUsed}</Typography>
            <Typography variant="h6" gutterBottom style={{ marginTop: '1rem' }}>This Month</Typography>
            <Typography>Queries made: {claudeUsage.monthlyUsage.queryCount}</Typography>
            <Typography>Tokens used: {claudeUsage.monthlyUsage.tokensUsed}</Typography>
            {claudeUsage.monthlyTokenLimit && (
              <>
                <Typography>Monthly limit: {claudeUsage.monthlyTokenLimit} tokens</Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={monthlyUsagePercentage} 
                  style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                />
                <Typography>
                  {claudeUsage.monthlyUsage.tokensUsed} / {claudeUsage.monthlyTokenLimit} tokens used ({monthlyUsagePercentage.toFixed(1)}%)
                </Typography>
              </>
            )}
          </>
        ) : (
          <Typography>No usage data available. Start using the translation feature to see your statistics.</Typography>
        )}
      </Paper>
    </Container>
  );
};

export default Settings;
