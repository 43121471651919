import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { useAuth } from '../context/AuthContext';
import { AppBar, Toolbar, Typography, Button, Select, MenuItem, Box } from '@mui/material';

const Header = () => {
  const { user, logout } = useAuth();
  const { t } = useTranslation();

  console.log('Current user in Header:', user);

  const changeLanguage = (event) => {
    const newLang = event.target.value;
    i18n.changeLanguage(newLang);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {t('appName')}
        </Typography>
        <Select
          value={i18n.language}
          onChange={changeLanguage}
          sx={{ mr: 2, color: 'white' }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="bg">Български</MenuItem>
        </Select>
        {user ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button color="inherit" component={Link} to="/">
              {t('createMenu')}
            </Button>
            <Button color="inherit" component={Link} to="/preview">
              {t('previewMenu')}
            </Button>
            <Button color="inherit" component={Link} to="/menu-history">
              {t('menuHistory')}
            </Button>
            <Button color="inherit" component={Link} to="/settings">
              {t('settings')}
            </Button>
            {user.isAdmin && (
              <Button color="inherit" component={Link} to="/admin">
                {t('adminDashboard')}
              </Button>
            )}
            <Button color="inherit" onClick={logout}>
              {t('logout')}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button color="inherit" component={Link} to="/login">
              {t('login')}
            </Button>
            <Button color="inherit" component={Link} to="/register">
              {t('register')}
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
