import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Switch,
  Snackbar,
  CircularProgress,
  Alert,
  TextField,
  Tab,
  Tabs,
  Box
} from '@mui/material';
import { useAuth } from '../context/AuthContext';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [claudeUsage, setClaudeUsage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.isAdmin) {
      navigate('/');
      return;
    }

    fetchUsers();
    fetchClaudeUsage();
  }, [user, navigate]);

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/users');
      if (Array.isArray(response.data)) {
        setUsers(response.data);
      } else {
        throw new Error('Unexpected data format received from server');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      let errorMessage = 'Error fetching users';
      if (error.response) {
        errorMessage += `: ${error.response.status} ${error.response.statusText}`;
        console.error('Error response:', error.response.data);
      } else if (error.request) {
        errorMessage += ': No response received from server';
      } else {
        errorMessage += `: ${error.message}`;
      }
      setError(errorMessage);
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchClaudeUsage = async () => {
    try {
      const response = await api.get('/users/all-claude-usage');
      setClaudeUsage(response.data);
    } catch (error) {
      console.error('Error fetching Claude usage:', error);
      setSnackbar({ open: true, message: 'Error fetching Claude usage data', severity: 'error' });
    }
  };

  const handleApproval = async (userId, isApproved) => {
    try {
      await api.put(`/users/${userId}/approve`, { isApproved });
      fetchUsers();
      setSnackbar({ open: true, message: 'User approval status updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating user approval:', error);
      setSnackbar({ open: true, message: 'Error updating user approval status', severity: 'error' });
    }
  };

  const handleAdminToggle = async (userId, isAdmin) => {
    try {
      await api.put(`/users/${userId}/admin`, { isAdmin });
      fetchUsers();
      setSnackbar({ open: true, message: 'User admin status updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating user admin status:', error);
      setSnackbar({ open: true, message: 'Error updating user admin status', severity: 'error' });
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await api.delete(`/users/${userId}`);
        fetchUsers();
        setSnackbar({ open: true, message: 'User deleted successfully', severity: 'success' });
      } catch (error) {
        console.error('Error deleting user:', error);
        setSnackbar({ open: true, message: 'Error deleting user', severity: 'error' });
      }
    }
  };

  const handleLimitChange = async (userId, newLimit) => {
    try {
      await api.post('/users/set-monthly-limit', { userId, limit: parseInt(newLimit, 10) });
      fetchClaudeUsage();
      setSnackbar({ open: true, message: 'Monthly token limit updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error setting monthly limit:', error);
      setSnackbar({ open: true, message: 'Error setting monthly token limit', severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
        <Alert severity="error">{error}</Alert>
        <Button onClick={fetchUsers} variant="contained" style={{ marginTop: '20px' }}>
          Retry
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
          <Tab label="User Management" />
          <Tab label="Claude Usage" />
        </Tabs>
      </Box>
      {activeTab === 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Approved</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Switch
                      checked={user.isApproved}
                      onChange={() => handleApproval(user._id, !user.isApproved)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={user.isAdmin}
                      onChange={() => handleAdminToggle(user._id, !user.isAdmin)}
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(user._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {activeTab === 1 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User Email</TableCell>
                <TableCell>Total Queries</TableCell>
                <TableCell>Total Tokens</TableCell>
                <TableCell>Monthly Queries</TableCell>
                <TableCell>Monthly Tokens</TableCell>
                <TableCell>Monthly Limit</TableCell>
                <TableCell>Set New Limit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {claudeUsage.map((usage) => (
                <TableRow key={usage._id}>
                  <TableCell>{usage.email}</TableCell>
                  <TableCell>{usage.totalQueryCount}</TableCell>
                  <TableCell>{usage.totalTokensUsed}</TableCell>
                  <TableCell>{usage.monthlyUsage?.queryCount || 0}</TableCell>
                  <TableCell>{usage.monthlyUsage?.tokensUsed || 0}</TableCell>
                  <TableCell>{usage.monthlyTokenLimit || 'No limit'}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      defaultValue={usage.monthlyTokenLimit || ''}
                      size="small"
                      onBlur={(e) => handleLimitChange(usage.userId, e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminPage;
