import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api/api';
import { useAuth } from '../context/AuthContext';
import { groupDishesByCategory } from '../helpers';
import {
  Container, Typography, TextField, Button, Grid, Paper,
  Select, MenuItem, FormControl, InputLabel,
  List, ListItem, ListItemText, ListItemSecondaryAction,
  IconButton, Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const categories = ['appetizers', 'mainCourses', 'desserts', 'beverages'];

const MenuCreator = () => {
  const { menuId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [dishes, setDishes] = useState([]);
  const [newDish, setNewDish] = useState({ category: '', name: '', price: '', description: '' });
  const [menuName, setMenuName] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [translatedDishes, setTranslatedDishes] = useState([]);
  const [isTranslating, setIsTranslating] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (menuId) {
      fetchMenu(menuId);
    }
  }, [menuId]);

  const fetchMenu = async (id) => {
    try {
      const response = await api.get(`/menus/${id}`);
      const menu = response.data;
      setMenuName(menu.name);
      setDishes(menu.bgMenu); // This now sets all the dishes
    } catch (error) {
      console.error('Error fetching menu:', error);
      setSnackbar({ open: true, message: t('errorFetchingMenu') });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDish({ ...newDish, [name]: value });
  };

  const addDish = () => {
    if (newDish.category && newDish.name && newDish.price) {
      setDishes(prevDishes => [...prevDishes, newDish]);
      setNewDish({ category: '', name: '', price: '', description: '' });
    }
  };

  const deleteDish = (index) => {
    setDishes(prevDishes => prevDishes.filter((_, i) => i !== index));
  };

  const saveMenu = async () => {
    try {
      const menuData = {
        name: menuName,
        bgMenu: dishes,
        userId: user.id
      };

      if (menuId) {
        await api.put(`/menus/${menuId}`, menuData);
      } else {
        await api.post('/menus', menuData);
      }

      setSnackbar({ open: true, message: t('menuSavedSuccessfully') });
      // Call navigateToPreview after saving the menu
      navigateToPreview();
    } catch (error) {
      console.error('Error saving menu:', error);
      setSnackbar({ open: true, message: t('errorSavingMenu') });
    }
  };

  const navigateToPreview = async () => {
    setIsTranslating(true);
    try {
      const translatedDishes = await Promise.all(dishes.map(async (dish) => {
        try {
          const response = await api.post('/translate', { 
            bulgarianDish: dish.name,
            category: dish.category,
            description: dish.description
          });
          return {
            ...dish,
            nameEn: response.data.nameEn,
            descriptionEn: response.data.description
          };
        } catch (error) {
          console.error('Error translating dish:', dish.name, error);
          return {
            ...dish,
            nameEn: dish.name, // Fallback to Bulgarian name if translation fails
            descriptionEn: dish.description // Fallback to Bulgarian description if translation fails
          };
        }
      }));

      navigate('/preview', { 
        state: { 
          dishes: dishes,
          translatedDishes: translatedDishes,
          menuName: menuName
        } 
      });
    } catch (error) {
      console.error('Error preparing menu preview:', error);
      setSnackbar({ open: true, message: t('errorPreparingMenuPreview') });
    } finally {
      setIsTranslating(false);
    }
  };

  const renderMenu = () => {
    const groupedDishes = groupDishesByCategory(dishes);
    
    return Object.entries(groupedDishes).map(([category, categoryDishes]) => (
      <div key={category}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: '10px' }}>
          {t(category)}
        </Typography>
        <List>
          {categoryDishes.map((dish, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={dish.name}
                secondary={`${dish.price} BGN${dish.description ? ` - ${dish.description}` : ''}`}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => {}}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => deleteDish(dishes.indexOf(dish))}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    ));
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>{menuId ? t('editMenu') : t('createMenu')}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>{t('addDish')}</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t('category')}</InputLabel>
              <Select
                name="category"
                value={newDish.category}
                onChange={handleInputChange}
              >
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>{t(category)}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              name="name"
              label={t('dishName')}
              value={newDish.name}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              name="price"
              label={t('price')}
              value={newDish.price}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              name="description"
              label={t('description')}
              multiline
              rows={3}
              value={newDish.description}
              onChange={handleInputChange}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={addDish} 
              fullWidth 
              style={{ marginTop: '20px' }}
            >
              {t('addDish')}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>{t('menuPreview')}</Typography>
            {renderMenu()}
          </Paper>
        </Grid>
      </Grid>
      <TextField
        fullWidth
        margin="normal"
        label={t('menuName')}
        value={menuName}
        onChange={(e) => setMenuName(e.target.value)}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={saveMenu} 
        fullWidth 
        style={{ marginTop: '20px' }}
      >
        {t('saveMenu')}
      </Button>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={navigateToPreview} 
        fullWidth 
        style={{ marginTop: '20px' }}
        disabled={isTranslating}
      >
        {isTranslating ? t('translating') : t('previewMenu')}
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Container>
  );
};

export default MenuCreator;
